import styled from "styled-components";

export const Address = styled.address`
    margin-bottom: ${({ withSpacing }) => (withSpacing ? "20px" : "0px")};

    p {
        margin-bottom: 0.1rem;
        font-size: 0.8rem;

        &.address-name {
            font-size: 1rem;
            margin-bottom: 0.2rem;
        }
    }
`;
