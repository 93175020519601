import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Group, Input, Select, Textarea, Checkbox, Radio } from '@peracto/peracto-ui'
import { Field } from 'formik'

import DateManagement from './DateManagement'
import PostcodeRules from './PostcodeRules'
import DeliveryCosts from './DeliveryCosts'
import PostcodeCharges from './PostcodeCharges'

import { GET_MANY, useClient } from '@peracto/client'
import { useConfig } from '@peracto/peracto-config'

export const MODE_ADD = 'add'
export const MODE_EDIT = 'edit'

const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
]

export const shippingServiceTypeOptions = [
    { label: 'Click & Collect', value: 'click_and_collect' },
    { label: 'Courier', value: 'courier' },
    { label: 'RFC', value: 'rfc' },
]

const ShippingServiceForm = ({ mode = MODE_EDIT, ...props }) => {
    const [countries, setCountries] = useState([])
    const [loading, setLoading] = useState(true)

    const { client } = useClient()
    const config = useConfig()
    const { shippingServices } = config.get('features', {})

    const fetchCountries = useCallback(async () => {
        try {
            const { data } = await client(GET_MANY, 'countries', {
                id: 'countries',
                validForBilling: true,
            })

            setCountries(data)

            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }, [client])

    useEffect(() => {
        fetchCountries()
    }, [fetchCountries])

    if (loading) {
        return (
            <div className="card">
                <div className="card-body">Loading...</div>
            </div>
        )
    }

    return (
        <Form autoComplete="off" {...props}>
            <Group key="core-data" id="core-data" name="Core Data">
                <Input
                    name="name"
                    label="Service Name"
                    required
                    help="The service name will be shown to users when their basket / items are eligable for the service."
                />

                <Select
                    name="type"
                    label="Type"
                    placeholder="Please select..."
                    required={true}
                    options={shippingServiceTypeOptions}
                />

                <Textarea
                    name="description"
                    label="Service Description"
                    help="The Service description is available to provide additional information not included within the Service name."
                />

                <Input
                    name="code"
                    label="Service Code"
                    required
                    help="Service code identifies the service and must be unique."
                />

                <Input
                    name="displayOrder"
                    label="Display Order"
                    help="Services as displayed in ascending order."
                />

                <Select
                    name="status"
                    label="Status"
                    placeholder="Please select..."
                    options={statusOptions}
                />
            </Group>

            <Group
                key="countries-default-charges"
                id="countries-default-charges"
                name="Countries and Default Charges"
            >
                <p className="help-text">
                    Default charges are set by country. Postcode specific rules and surcharges are
                    always added to this cost (which can be 0)
                </p>

                <DeliveryCosts name="costs" countries={countries} />
            </Group>

            {shippingServices?.deliveryDays && (
                <Group
                    key="service-availability"
                    id="service-availability"
                    name="Service Availability"
                >
                    <p className="help-text">
                        Which delivery days is this Service <u>available</u> for?
                    </p>

                    <Checkbox name="deliveryDays.day_0" label="Sunday" />
                    <Checkbox name="deliveryDays.day_1" label="Monday" />
                    <Checkbox name="deliveryDays.day_2" label="Tuesday" />
                    <Checkbox name="deliveryDays.day_3" label="Wednesday" />
                    <Checkbox name="deliveryDays.day_4" label="Thursday" />
                    <Checkbox name="deliveryDays.day_5" label="Friday" />
                    <Checkbox name="deliveryDays.day_6" label="Saturday" />

                    <p className="help-text mt-4">
                        Which delivery dates is this Service <u>available</u> for?
                    </p>

                    <DateManagement name="dateInclusions" type="inclusion" />

                    <p className="help-text mt-4">
                        Which delivery dates is this Service <u>unavailable</u> for?
                    </p>

                    <DateManagement name="dateExclusions" type="exclusion" />

                    <Input
                        name="leadTimeDays"
                        label="Lead Time Days"
                        help="The number of days between an order being placed and the earliest available delivery slot for the Shipping Service."
                        required
                        type="number"
                        min={0}
                    />

                    <Input
                        name="cutoffTime"
                        label="Cut Off Time"
                        help="The last time *of any day* that an order can be placed in order to be eligible for a future delivery slot for the Shipping Service."
                        type="time"
                        step={1}
                    />
                </Group>
            )}

            <Group key="postcode-rules" id="postcode-rules" name="Postcode Rules">
                <p className="help-text">
                    Use postcode rules to control service availability by Postcode Outcode and
                    Sector.
                </p>

                <Radio
                    name="postcodeRulesMode"
                    label=""
                    required
                    options={[
                        {
                            label: 'This service is available to all postcodes',
                            value: 'all',
                        },
                        {
                            label: 'This service is not available to the following postcodes',
                            value: 'excluded',
                        },
                        {
                            label: 'This service is only available to the following postcodes',
                            value: 'included',
                        },
                    ]}
                />

                <Field name="postcodeRulesMode">
                    {({ field }) => (
                        <>
                            {field.value !== 'all' && (
                                <>
                                    <div className="form-group">
                                        {/* We only support UK at the moment but this list will be built using the countries selected */}
                                        <hr />
                                        <label>United Kingdom</label>
                                        <PostcodeRules name="postcodeRules" />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Field>
            </Group>

            <Group
                key="uk-postcode-charges"
                id="uk-postcode-charges"
                name="United Kingdom Postcode Charges"
            >
                <p className="help-text">
                    If your delivery charges differ by postcode, manage them by exception here.
                </p>

                <PostcodeCharges name="postcodeCosts" />
            </Group>
        </Form>
    )
}

ShippingServiceForm.displayName = 'ShippingServiceForm'

ShippingServiceForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default ShippingServiceForm
