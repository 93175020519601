import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import { HydraTable, Heading, TextFilter, SelectFilter, StyledCheckbox } from '@peracto/peracto-ui'
import { shippingServiceTypeOptions } from '../ShippingServiceForm'

import { GET_LIST, useClient } from '@peracto/client'

const statusValues = {
    active: 'Active',
    inactive: 'Inactive',
}

const ShippingServiceList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        description: false,
        displayOrder: false,
        type: true,
    })

    const { client } = useClient()

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
                ),
                Cell: ({ row }) => <StyledCheckbox {...row.getToggleRowSelectedProps()} />,
                selectable: false,
            },
            {
                Header: 'Service Name',
                accessor: 'name',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Service Name" {...props} />,
                Cell: (row) => <Link to={`${row.row.original.id}`}>{row.cell.value}</Link>,
                selectable: false,
            },
            {
                Header: 'Service Code',
                accessor: 'code',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Service Code" {...props} />,
                selectable: false,
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortType: 'basic',
                Filter: (props) => (
                    <SelectFilter label="Type" options={shippingServiceTypeOptions} {...props} />
                ),
                Cell: ({ cell: { value } }) => {
                    const option = shippingServiceTypeOptions.find((opt) => opt.value === value)

                    return <span>{option?.label || '-'}</span>
                },
                show: selectedColumns['type'],
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortType: 'basic',
                Filter: (props) => (
                    <SelectFilter
                        label="Status"
                        options={[
                            { value: 'active', label: 'Active' },
                            { value: 'inactive', label: 'Inactive' },
                        ]}
                        {...props}
                    />
                ),
                Cell: ({ cell: { value } }) => (
                    <span
                        className={classNames('badge', 'badge-pill', {
                            'badge-success': value === 'active',
                            'badge-danger': value === 'inactive',
                        })}
                    >
                        {statusValues[value]}
                    </span>
                ),
                selectable: false,
            },
            {
                Header: 'Service Description',
                accessor: 'description',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Service Description" {...props} />,
                selectable: true,
                show: selectedColumns['description'],
            },
            {
                Header: 'Display Order',
                accessor: 'displayOrder',
                sortType: 'basic',
                Filter: (props) => <TextFilter label="Display Order" {...props} />,
                selectable: true,
                show: selectedColumns['displayOrder'],
            },
        ],
        [selectedColumns]
    )

    const onFetchData = (options) => client(GET_LIST, 'shipping-services', options)

    return (
        <>
            <Helmet>
                <title>Shipping Services | Peracto</title>
            </Helmet>
            <Heading name="Shipping Services">
                <div className="d-flex align-items-center ml-2">
                    <Link className="btn btn-primary" to="/shipping-services/add">
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Service
                    </Link>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    status: '',
                                    name: '',
                                    code: '',
                                    type: '',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShippingServiceList
