import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

const PromotionCodeInput = ({ push }) => {
    const initialPromotionCode = {
        code: '',
        maximumUses: 1,
        maximumUsesPerEmail: 1,
    }

    const [promotionCode, setPromotionCode] = useState({ ...initialPromotionCode })

    return (
        <div className="form-group">
            <div className="form-row">
                <div className="col-12 col-lg-4 mb-3 mb-md-0">
                    <label htmlFor="promotion-code__code">Promotion Code</label>
                    <input
                        id="promotion-code__code"
                        className="form-control"
                        value={promotionCode.code}
                        placeholder="Code"
                        onChange={(e) =>
                            setPromotionCode({
                                ...promotionCode,
                                code: e.target.value,
                            })
                        }
                    />
                </div>

                <div className="col-12 col-lg-4 mb-3 mb-md-0">
                    <label htmlFor="promotion-code__max-uses">Maximum Uses</label>

                    <input
                        id="promotion-code__max-uses"
                        className="form-control"
                        value={promotionCode.maximumUses}
                        placeholder="Maximum Uses"
                        type="number"
                        min="1"
                        onChange={(e) =>
                            setPromotionCode({
                                ...promotionCode,
                                maximumUses: e.target.value,
                            })
                        }
                    />
                </div>

                <div className="col-12 col-lg-4 mb-3 mb-md-0">
                    <label htmlFor="promotion-code__max-uses-per-email">Max. Uses Per Email</label>

                    <input
                        id="promotion-code__max-uses-per-email"
                        className="form-control"
                        value={promotionCode.maximumUsesPerEmail}
                        placeholder="Max. Uses Per Email"
                        type="number"
                        min="1"
                        onChange={(e) =>
                            setPromotionCode({
                                ...promotionCode,
                                maximumUsesPerEmail: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="col-12 text-right">
                    <button
                        className="btn btn-link px-0 mt-2"
                        type="button"
                        onClick={() => {
                            // prevent adding the rule if no postcode area
                            if (promotionCode.code) {
                                push(promotionCode)

                                setPromotionCode(initialPromotionCode)
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Code
                    </button>
                </div>
            </div>
        </div>
    )
}

PromotionCodeInput.propTypes = {
    push: PropTypes.func.isRequired,
}

export default PromotionCodeInput
