export const userRoles = [
    { label: 'Admin', value: 'ROLE_ADMIN' },
    { label: 'User', value: 'ROLE_USER' },
    { label: 'Commercial', value: 'ROLE_COMMERCIAL' },
    { label: 'Finance', value: 'ROLE_FINANCE' },
    { label: 'Marketing', value: 'ROLE_MARKETING' },
    { label: 'Operations', value: 'ROLE_OPERATIONS' },
    { label: 'Trade Support', value: 'ROLE_TRADE_SUPPORT' },
    { label: 'Super Admin', value: 'ROLE_SUPER_ADMIN' },
    { label: 'Parts Arena', value: 'ROLE_PARTS_ARENA' },
]
