import { menuItems } from '@peracto/peracto'

const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_TRADE_SUPPORT = 'ROLE_TRADE_SUPPORT'
const ROLE_MARKETING = 'ROLE_MARKETING'
const ROLE_COMMERCIAL = 'ROLE_COMMERCIAL'
const ROLE_FINANCE = 'ROLE_FINANCE'
const ROLE_OPERATIONS = 'ROLE_OPERATIONS'

const menu = {
    ...menuItems,

    content: {
        ...menuItems.content,
        roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN],

        children: {
            ...menuItems.content.children,
            forms: {
                ...menuItems.content.children.forms,
                roles: [ROLE_SUPER_ADMIN, ROLE_MARKETING, ROLE_OPERATIONS],

                children: {
                    ...menuItems.content.children.forms.children,
                    allForms: {
                        ...menuItems.content.children.forms.children.allForms,
                        roles: [ROLE_SUPER_ADMIN, ROLE_MARKETING, ROLE_OPERATIONS],
                    },
                    formFields: {
                        ...menuItems.content.children.forms.children.formFields,
                        roles: [ROLE_SUPER_ADMIN, ROLE_MARKETING, ROLE_OPERATIONS],
                    },
                    formSubmissions: {
                        ...menuItems.content.children.forms.children.formSubmissions,
                        roles: [ROLE_SUPER_ADMIN, ROLE_MARKETING, ROLE_OPERATIONS],
                    },
                },
            },
        },
    },

    locations: {
        ...menuItems.locations,
        roles: [ROLE_SUPER_ADMIN, ROLE_FINANCE, ROLE_OPERATIONS],
    },

    configuration: {
        ...menuItems.configuration,
        roles: [ROLE_SUPER_ADMIN, ROLE_COMMERCIAL, ROLE_ADMIN],

        children: {
            ...menuItems.configuration.children,
            settings: {
                ...menuItems.configuration.children.settings,
                roles: [ROLE_SUPER_ADMIN, ROLE_TRADE_SUPPORT, ROLE_MARKETING, ROLE_OPERATIONS],
            },
            navigation: {
                ...menuItems.configuration.children.navigation,
                roles: [ROLE_SUPER_ADMIN, ROLE_MARKETING, ROLE_COMMERCIAL, ROLE_OPERATIONS],
            },
            redirects: {
                ...menuItems.configuration.children.redirects,
                roles: [ROLE_SUPER_ADMIN, ROLE_TRADE_SUPPORT, ROLE_FINANCE, ROLE_OPERATIONS],
            },
            lastImportStatus: {
                label: 'Import Enquiry',
                path: '/last-imported-files',
                sortOrder: 60,
                roles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_COMMERCIAL],
            },
        },
    },
}

export default menu
