import styled from 'styled-components'

export const DatePickerContainer = styled.div`
    .react-datepicker {
        font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
`
