import React from 'react'
import { ThemeProvider } from 'styled-components'

import Peracto from '@peracto/peracto'
import Config from '@peracto/peracto-config'
import PeractoUser from '@peracto/peracto-user'
import PeractoCatalogue from '@peracto/peracto-catalogue'
import PeractoCommon from '@peracto/peracto-common'
import PeractoLocations from '@peracto/peracto-locations'
import PeractoContent from '@peracto/peracto-content'
import PeractoPromotions from '@peracto/peracto-promotions'

import { UserEdit, UserForm, UserList } from './packages/User'
import { LocationsForm } from './packages/Locations'
import { PromotionsForm, PromotionsList } from './packages/Promotions'
import Integrations from './packages/Integrations'
import LastImportedFiles from './packages/LastImportedFiles'
import {
    ShippingServiceForm,
    ShippingServiceList,
    OrdersList,
    OrdersView,
} from './packages/Catalogue'

import { AdminTheme, GlobalStyles, EditorTheme } from './theme'
import menu from './menu'
import blocks from './blocks'

const config = new Config({
    api: process.env.REACT_APP_API,
    modules: [
        Integrations(),
        LastImportedFiles(),
        PeractoLocations({ LocationsForm }),
        PeractoUser({ UserEdit, UserForm, UserList }),
        PeractoCatalogue({
            ShippingServiceForm,
            ShippingServiceList,
            OrdersList,
            OrdersView,
        }),
        PeractoCommon(),
        PeractoContent(),
        PeractoPromotions({ PromotionsForm, PromotionsList }),
    ],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: [
            { label: 'Brand Blue', value: '#003d73' },
            { label: 'Brand Red', value: '#E30613' },
            { label: 'Brand Yellow', value: '#ffed00' },
            { label: 'Brand Green', value: '#098d45' },
        ],
        locales: ['en_GB'],
        blocks,
        inputTypes: {},
        globalStyles: GlobalStyles,
    },
    menu,
    features: {
        products: {
            bulkPricing: true,
            showPricesAsCurrency: true,
            hideClearancePrice: true,
            hideSalePrice: true,
            showRRPAsWasPrice: true,
            productVariants: true,
            maxQuantity: true,
        },
        shippingServices: {
            deliveryDays: true,
        },
        promotions: {
            orderTransferCode: true,
        },
    },
})

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
)

export default App
