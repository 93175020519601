import React, { useState } from "react";
import { Form, Group, Input } from "@peracto/peracto-ui";
import { toast } from "react-toastify";
import { object, string } from "yup";
import * as S from "./styled";

// import { GET_ONE, useClient } from "@peracto/client";

const RequestForm = ({ name, type }) => {
  const [data, setData] = useState();

  /* @todo 
    The current client fetcher doesn't seem to play well with the new kerridge/gas-safe endpoints, thus the hardcoded values for now. 
    The fetcher URLs will need to be upated as appropriate and hardcoded data removed. 
    Fake fetcher should also be removed.
  */

  // const { client } = useClient();

  const schema = object().shape({
    accNumber: string().required("Account Number is required"),
  });

  const FAKE_FETCHER = () => {
    toast.success("Request sent successfully!");
    setData({
      accountName: "This is the account name",
      accountNumber: "C009383",
      balance: 100.0,
      credit: 1000.0,
      creditLimit: 99999.99,
      creditStatusCode: "A",
    });
  };

  // const fetchData = async (e) => {
  //   let fetchUrl = "";

  //   switch (type) {
  //     case "kerridge":
  //       fetchUrl = `http://localhost:8000/kerridge/get-account-details?account_number=${e.accNumber}`;
  //       break;

  //     case "gsr":
  //       fetchUrl = `http://localhost:8000/gas-safe/get-business?registration_number=${e.accNumber}`;
  //       break;

  //     default:
  //       break;
  //   }

  //   try {
  //     const data = await fetch(fetchUrl, {
  //       method: "GET",
  //       headers: { "Content-Type": "application/json" },
  //     });

  //     const res = await data.json();
  //     setData(res);

  //     toast.success("Request sent successfully!");
  //   } catch (e) {
  //     console.error(e);
  //     toast.error("Whoops, there was a problem...");
  //   }
  // };

  return (
    <Group name={name} id="request-form">
      <Form
        onSubmit={() => FAKE_FETCHER()}
        showActionPanel={false}
        className="mb-2"
        schema={schema}
      >
        <Input name="accNumber" label="Account Number" />
        <button className="btn btn-primary" children="Request" type="submit" />
      </Form>

      {data && (
        <>
          <S.ResponseTitle children="Response" />
          <S.Response
            children={
              <pre>
                <code>{JSON.stringify(data, null, 4)}</code>
              </pre>
            }
          />
        </>
      )}
    </Group>
  );
};

export default RequestForm;
