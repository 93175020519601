import { theme } from '@chakra-ui/core'
import styled from 'styled-components'
import { css } from '@emotion/core'

export const EditorTheme = {
    ...theme,
    colors: {
        ...theme.colors,
        brand: {
            brandBlue: '#003d73',
            brandRed: '#E30613',
            brandYellow: '#ffed00',
            brandGreen: '#098d45',
        },
    },
}

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 1024,
        desktop: 1440,
        desktopL: 1700,
    },
}

export const StyledButton = styled.button`
    padding: 0;
    border: none;
    background: none;
`

export const GlobalStyles = css``
