import styled from 'styled-components'

export const OrderAddress = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;

    .address-title {
        font-size: 1rem;
        margin-bottom: 0.2rem;
        color: #333;
    }

    p {
        margin-bottom: 0.1rem;
        font-size: 0.8rem;
    }
`
