import styled from "styled-components";

export const ResponseTitle = styled.h3`
  color: #c8c8c8;
  font-size: 0.8rem;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
`;

export const Response = styled.div`
  padding: 1rem;
  background-color: #f2f2f2;
`;
