import React from 'react'
import { Select, Input } from '@peracto/peracto-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'

const CONDITION_TYPES = [{ label: 'Basket Subtotal', value: 'basket.subtotal' }]

export const OPERATION_TYPES = [
    { label: 'Equals', value: '=' },
    { label: 'Greater than', value: '>' },
    { label: 'Less than', value: '<' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Less than or equal', value: '<=' },
    { label: 'Not equal to', value: '!=' },
]

const MinimumRequirementsRow = ({ index, form, field, remove }) => {
    return (
        <div key={`condition_${index}`}>
            <div className="row mb-1">
                <div className="col-12">
                    <div className="form-row">
                        <div className="col-12 col-md-4">
                            <Select
                                name={`${field.name}.${index}.condition`}
                                options={CONDITION_TYPES}
                            />
                        </div>

                        <div className="col-12 col-md-4">
                            <Select
                                name={`${field.name}.${index}.operation`}
                                options={OPERATION_TYPES}
                            />
                        </div>

                        <div className="col-12 col-md-3">
                            <Input
                                type="number"
                                name={`${field.name}.${index}.value`}
                                min="0"
                                step="any"
                            />
                        </div>

                        <div className="col-12 col-md-1 text-right">
                            <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                    remove(index)
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MinimumRequirementsRow
