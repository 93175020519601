import React, { useEffect, useState, useCallback } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'
import { Helmet } from 'react-helmet'

import { Heading, Group, FormActions } from '@peracto/peracto-ui'
import { useConfig } from '@peracto/peracto-config'

import { GET_ONE, useClient } from '@peracto/client'
import * as S from './styled'

import dayjs from 'dayjs'

const currencySymbols = {
    GBP: '£',
    USD: '$',
    EUR: '€',
}

const transferStatusValues = {
    successful: 'Transferred Successfully',
    failed: 'Transfer Failed',
    pending: 'Pending',
    retrying: 'Retrying',
}

export const formatAsCurrency = (price = 0, currency = 'GBP') => {
    return `${currencySymbols[currency]}${(price / 100).toFixed(2)}`
}

const OrdersViewContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="View Order">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link className="btn btn-outline-primary" to="/orders">
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Back to Orders
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    )
}

const OrderAddress = ({ address, title = '' }) => (
    <S.OrderAddress>
        <p className="address-title">{title}</p>
        {(address?.forename || address?.surname) && (
            <p>
                {address?.forename} {address?.surname}
            </p>
        )}
        {address?.line1 && <p>{address?.line1}</p>}
        {address?.line2 && <p>{address?.line2}</p>}
        {address?.line3 && <p>{address?.line3}</p>}
        {address?.line4 && <p>{address?.line4}</p>}
        {address?.city && <p>{address?.city}</p>}
        {address?.postalCode && <p>{address?.postalCode}</p>}
        {address?.county && <p>{address?.county}</p>}
        {address?.country && <p>{address?.country}</p>}
        {address?.telephone && <p>{address?.telephone}</p>}
    </S.OrderAddress>
)

const OrdersView = ({ location: { pathname } }) => {
    const { client } = useClient()
    const [loading, setLoading] = useState(true)
    const [redirect, setRedirect] = useState()
    const [orderData, setOrderData] = useState()

    const config = useConfig()
    const { orders } = config.get('features', {})

    // show prices as tax feature flag - default: true
    const includeTax =
        orders && orders.hasOwnProperty('includeTax') ? orders.includeTax === true : true

    const fetchOrder = useCallback(async () => {
        try {
            const { data, response } = await client(GET_ONE, 'orders', {
                id: pathname,
            })

            if (response.status === 404) {
                setRedirect('/orders')
                setLoading(false)
                return
            }

            setOrderData(data)
            setLoading(false)
        } catch (e) {
            console.error(e)
            setRedirect('/orders')
            setLoading(false)
        }
    }, [client, pathname])

    useEffect(() => {
        if (!orderData) fetchOrder()
    }, [fetchOrder, orderData])

    if (loading) {
        return (
            <OrdersViewContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </OrdersViewContainer>
        )
    }

    return (
        <OrdersViewContainer>
            {redirect ? (
                <Redirect to={redirect} />
            ) : (
                <>
                    <Helmet>
                        <title>
                            {orderData.reference ? `Order #: ${orderData.reference}` : 'Order'} |
                            View | Peracto
                        </title>
                    </Helmet>
                    {
                        <>
                            <FormActions>
                                <span className="mr-3">
                                    Order ID:{' '}
                                    <span className="font-weight-bold">
                                        {orderData.reference || 'n/a'}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Customer Reference:{' '}
                                    <span className="font-weight-bold">
                                        {orderData.customerReference || 'n/a'}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Status:{' '}
                                    <span className="font-weight-bold text-capitalize">
                                        {orderData.status}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Created:{' '}
                                    <span className="font-weight-bold text-capitalize">
                                        {dayjs(new Date(orderData.createdAt)).format(
                                            'YYYY-MM-DD HH:mm:ss'
                                        )}
                                    </span>
                                </span>
                            </FormActions>

                            <Group key="items" id="items" name="Items">
                                <table className="table">
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="45%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <thead>
                                        <tr>
                                            <th className="border-top-0"></th>
                                            <th className="border-top-0"></th>
                                            <th className="border-top-0 text-right">Quantity</th>
                                            <th className="border-top-0 text-right">
                                                Total Discount
                                            </th>
                                            <th className="border-top-0 text-right">Line Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {orderData?.lines?.map((line, idx) => (
                                            <tr key={`product_${idx}`}>
                                                <td>
                                                    <img
                                                        src={line.item?.image}
                                                        alt={line.item.name}
                                                        className="w-100"
                                                    />
                                                </td>
                                                <td>
                                                    <p className="font-weight-bold mb-0">
                                                        {line.item?.name}
                                                    </p>
                                                    {line.item?.sku && (
                                                        <p className="mb-0">{line.item?.sku}</p>
                                                    )}
                                                    {line.line && (
                                                        <p className="mb-0">
                                                            {formatAsCurrency(
                                                                includeTax
                                                                    ? line.priceIncTaxBeforeDiscount
                                                                    : line.priceExcTaxBeforeDiscount,
                                                                orderData?.currency
                                                            )}
                                                        </p>
                                                    )}
                                                </td>
                                                <td className="text-right">{line.quantity}</td>
                                                <td className="text-right">
                                                    <p className="mb-0">
                                                        {line?.promotions
                                                            ? formatAsCurrency(
                                                                  line.promotions?.reduce(
                                                                      (acc, curVal) => {
                                                                          return (
                                                                              acc +
                                                                              (includeTax
                                                                                  ? curVal?.discount
                                                                                        ?.incTax
                                                                                  : curVal?.discount
                                                                                        ?.excTax)
                                                                          )
                                                                      },
                                                                      0
                                                                  ),
                                                                  orderData?.currency
                                                              )
                                                            : formatAsCurrency(
                                                                  0,
                                                                  orderData?.currency
                                                              )}
                                                    </p>
                                                </td>
                                                <td className="text-right">
                                                    {formatAsCurrency(
                                                        includeTax
                                                            ? line?.linePriceTotal?.incTax
                                                            : line?.linePriceTotal?.excTax,
                                                        orderData?.currency
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <hr />

                                <table className="table table-sm table-borderless">
                                    <colgroup>
                                        <col width="15%" />
                                        <col width="70%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">Subtotal</td>
                                            <td>
                                                {orderData?.lines?.length} item
                                                {orderData?.lines?.length !== 1 ? 's' : ''}
                                            </td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    includeTax
                                                        ? orderData?.totalIncTaxBeforeDiscount
                                                        : orderData?.totalExcTaxBeforeDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Shipping</td>
                                            <td>{orderData.shippingServiceName}</td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    orderData?.shippingTotalAfterDiscount ||
                                                        orderData?.shippingTotal,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Total Discount</td>
                                            <td></td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    orderData?.totalDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        {includeTax && (
                                            <tr>
                                                <td>Tax</td>
                                                <td></td>
                                                <td className="text-right">
                                                    {formatAsCurrency(
                                                        orderData?.totalTaxAfterDiscount,
                                                        orderData?.currency
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr className="border-top">
                                            <td className="font-weight-bold">Paid by customer</td>
                                            <td></td>
                                            <td className="text-right font-weight-bold">
                                                {formatAsCurrency(
                                                    includeTax
                                                        ? orderData?.totalIncTaxAfterDiscount
                                                        : orderData?.totalExcTaxAfterDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Group>

                            <Group key="customer" id="customer" name="Customer">
                                <S.OrderAddress>
                                    {(orderData?.user?.firstName || orderData?.user?.lastName) && (
                                        <p className="address-title">{`${orderData?.user?.firstName} ${orderData?.user?.lastName}`}</p>
                                    )}
                                    {(orderData?.email || orderData?.telephone) && (
                                        <p>
                                            {orderData?.email ? orderData?.email : ''}
                                            {orderData?.email && orderData?.telephone && ' / '}
                                            {orderData?.telephone ? orderData?.telephone : ''}
                                        </p>
                                    )}
                                </S.OrderAddress>

                                {orderData?.deliveryAddress && (
                                    <>
                                        <hr />

                                        <OrderAddress
                                            address={orderData.deliveryAddress}
                                            title="Shipping Address"
                                        />
                                    </>
                                )}

                                {orderData?.deliveryAddress && (
                                    <>
                                        <hr />

                                        <OrderAddress
                                            address={orderData.billingAddress}
                                            title="Billing Address"
                                        />
                                    </>
                                )}
                            </Group>

                            <Group key="payment" id="payment" name="Payment">
                                {orderData.transferStatus && (
                                    <table className="table table-sm table-borderless">
                                        <colgroup>
                                            <col width="20%" />
                                            <col width="80%" />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-bold">
                                                    Transfer Status:
                                                </td>
                                                <td>
                                                    {
                                                        transferStatusValues[
                                                            orderData.transferStatus.toLowerCase()
                                                        ]
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </Group>

                            <Group key="shipping" id="shipping" name="Shipping">
                                <table className="table table-sm table-borderless">
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="80%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">Service:</td>
                                            <td>{orderData?.shippingServiceName}</td>
                                        </tr>

                                        {orderData.shippingDate && (
                                            <tr>
                                                <td className="font-weight-bold">Shipping Date:</td>
                                                <td>
                                                    {dayjs(new Date(orderData.shippingDate)).format(
                                                        'YYYY-MM-DD HH:mm:ss'
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td className="font-weight-bold">Delivery Notes:</td>
                                            <td>
                                                {orderData?.deliveryNotes ? (
                                                    orderData?.deliveryNotes
                                                ) : (
                                                    <span className="text-italic">n/a</span>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Group>
                        </>
                    }
                </>
            )}
        </OrdersViewContainer>
    )
}

export default OrdersView
