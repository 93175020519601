import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'

import ShippingDateRange from './ShippingDateRange'

const DateManagement = ({ name, type }) => (
    <Field name={name}>
        {({ field }) => (
            <FieldArray name={field.name}>
                {({ push, remove }) => (
                    <>
                        {field?.value?.length > 0 && (
                            <table className="table table-sm">
                                <colgroup>
                                    <col width="45%" />
                                    <col width="45%" />
                                    <col width="10%" />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th className="text-right">Remove</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {field.value.map((item, index) => (
                                        <tr key={`${type}s-${index}`}>
                                            <td>{moment(item.startDate).format('YYYY-MM-DD')}</td>
                                            <td>
                                                {item.endDate
                                                    ? moment(item.endDate).format('YYYY-MM-DD')
                                                    : '-'}
                                            </td>
                                            <td className="text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        <div className="form-group">
                            <label className="mb-1">Add Date {upperFirst(type)}</label>
                            <ShippingDateRange push={push} type={type} />
                        </div>
                    </>
                )}
            </FieldArray>
        )}
    </Field>
)

DateManagement.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

export default DateManagement
