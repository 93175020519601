import React, { useCallback, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowAltCircleLeft'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import omit from 'lodash/omit'

import { Heading, NotFoundMessage, UnauthorisedMessage } from '@peracto/peracto-ui'
import {
    CREATE,
    GET_ONE,
    GET_MANY,
    UPDATE,
    useClient,
    getSchemaFromResource,
} from '@peracto/client'

const UserEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit User">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link className="btn btn-outline-primary" to="/users">
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
                        Back to Users
                    </Link>
                </div>
            </Heading>

            {children}
        </div>
    )
}

const UserEdit = ({ UserForm, location: { pathname } }) => {
    const { client, getResource } = useClient()
    const [loading, setLoading] = useState(true)
    const [unauthorised, setUnauthorised] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [redirect, setRedirect] = useState()

    const [formData, setFormData] = useState()
    const [countryData, setCountryData] = useState()

    const fetchCountries = useCallback(async () => {
        try {
            const { data: countryData, response: countryResponse } = await client(
                GET_MANY,
                'countries',
                {
                    id: 'countries',
                }
            )

            setCountryData(countryData)

            setLoading(false)

            if (countryResponse.status === 404) {
                setRedirect('/users')
            }
        } catch (e) {
            console.error(e)
            setRedirect('/users')
        }
    }, [client])

    const fetchUser = useCallback(async () => {
        try {
            const { data: userData, response: userResponse } = await client(GET_ONE, 'users', {
                id: pathname,
            })

            const { data: addressData, response: addressResponse } = await client(
                GET_MANY,
                'addresses',
                {
                    id: `addresses?user=${pathname}`,
                }
            )

            const customerGroup = userData.customerGroup ? userData.customerGroup['@id'] : null

            setFormData({
                user: {
                    ...userData,
                    customerGroup,
                },
                addresses: addressData,
            })

            fetchCountries()

            if (userResponse.status === 404 || addressResponse.status === 404) {
                setRedirect('/users')
            }
        } catch (e) {
            console.error(e)

            if (e.status === 403) {
                setUnauthorised(true)
            }

            if (e.status === 404) {
                setNotFound(true)
            }

            setLoading(false)
            setRedirect('/users')
        }
    }, [client, fetchCountries, pathname])

    const onSaveAddress = async (address) => {
        const hasId = address['@id']

        try {
            await client(hasId ? UPDATE : CREATE, 'addresses', {
                id: hasId ? address['@id'] : null,
                data: {
                    ...address,
                    user: formData.user.id,
                    isDefault: false,
                    readOnly: false,
                },
            })

            const { data: addressData } = await client(GET_MANY, 'addresses', {
                id: `addresses?user=${pathname}`,
            })

            setFormData({
                ...formData,
                addresses: addressData,
            })

            toast.success('Address saved successfully!')
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    const schema = yup.object().shape({
        user: getSchemaFromResource(getResource('users')).shape({
            password: yup.string().notRequired(),
            title: yup.string().nullable(),
            telephone: yup.string().nullable(),
        }),
    })

    const onSubmit = async (data, actions) => {
        try {
            const fieldsToDelete = ['registeredAddressKerridge']

            const response = await client(UPDATE, 'users', {
                id: pathname,
                data: omit(data.user, ...fieldsToDelete.map((val) => !data.user[val] && val)),
            })

            if (response.data.violations && response.data.violations.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false)
                response.data.violations.map((error) =>
                    actions.setFieldError(`user.${error.propertyPath}`, error.message)
                )
            } else {
                actions.setSubmitting(false)
                actions.resetForm(data)
                setFormData(data)
                toast.success('User successfully updated!')
            }
        } catch (e) {
            console.error(e)

            e.error.body.violations.map((error) =>
                actions.setFieldError(`user.${error.propertyPath}`, error.message)
            )

            toast.error('Whoops, there was a problem...')
            actions.setSubmitting(false)
        }
    }

    if (loading) {
        return (
            <UserEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </UserEditContainer>
        )
    }

    if (unauthorised) {
        return <UnauthorisedMessage />
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/users"
                message="The user you're looking for could not be found"
                buttonLabel="Go to Users"
            />
        )
    }

    return (
        <UserEditContainer>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>
                    {`${formData.user.firstName} ${formData.user.lastName}`} | Edit | Peracto
                </title>
            </Helmet>

            <UserForm
                values={formData}
                onSubmit={onSubmit}
                schema={schema}
                setFormData={setFormData}
                onSaveAddress={onSaveAddress}
                countries={countryData}
                fetchUser={fetchUser}
                testId="edit"
            />
        </UserEditContainer>
    )
}

export default UserEdit
