import React from "react";
import { Route, Switch } from "react-router-dom";
import { LastImportedFilesList } from "./LastImportedFilesList/index";

export default () => {
    return {
        routes: [
            {
                path: "/last-imported-files",
                render: ({ match }) => (
                    <Switch>
                        <Route path={`${match.url}`} exact component={LastImportedFilesList} />
                    </Switch>
                ),
            },
        ],
    };
};
