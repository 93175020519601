import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { GET_LIST, GET_ONE, useClient } from '@peracto/client'
import { Heading } from '@peracto/peracto-ui'

const Container = ({ children }) => {
    return (
        <div className="row mb-4">
            <div className="col">
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        {children}
                    </li>
                </ul>
            </div>
        </div>
    )
}

const DownloadButton = ({ downloadItem, id, type }) => {
    return (
        <button className="btn btn-link" onClick={() => downloadItem(id, type)}>
            <FontAwesomeIcon icon={faDownload} />
        </button>
    )
}

export const LastImportedFilesList = () => {
    const [eodFile, setEodFile] = useState()
    const [bomFile, setBomFile] = useState()
    const [deltaFile, setDeltaFile] = useState()

    const [eodDate, setEodDate] = useState('')
    const [bomDate, setBomDate] = useState('')
    const [deltaDate, setDeltaDate] = useState('')

    const { client } = useClient()

    const parseDate = (id) => {
        // Parse a string that looks like this: 20200527105428
        const year = id.substr(0, 4)
        const month = id.substr(4, 2)
        const day = id.substr(6, 2)
        const hours = id.substr(8, 2)
        const mins = id.substr(10, 2)

        return `${day}/${month}/${year} - ${hours}:${mins}`
    }

    useEffect(() => {
        async function fetchEodData() {
            const { data } = await client(GET_LIST, 'eod-import-files', {
                id: 'eod-import-files',
            })

            const unNestedEodData = data[0]

            setEodFile(unNestedEodData)

            const parsedEodDate = parseDate(unNestedEodData.originId)

            setEodDate(parsedEodDate)
        }

        async function fetchBomData() {
            const { data } = await client(GET_LIST, 'bom-import-files', {
                id: 'bom-import-files',
            })

            const unNestedBomData = data[0]

            setBomFile(unNestedBomData)

            const parsedBomDate = parseDate(unNestedBomData.originId)

            setBomDate(parsedBomDate)
        }

        async function fetchDeltaData() {
            const { data } = await client(GET_LIST, 'delta-file-imports', {
                id: 'delta-import-files',
            })

            const unNestedDeltaData = data[0]

            setDeltaFile(unNestedDeltaData)

            const parsedDeltaDate = parseDate(unNestedDeltaData.originId)

            setDeltaDate(parsedDeltaDate)
        }

        fetchEodData()

        fetchBomData()

        fetchDeltaData()
    }, [client])

    const downloadItem = async (id, type) => {
        // Type is `eod` or `bom`
        try {
            const { data } = await client(GET_ONE, `${type}-import-files`, {
                id,
            })
            window.location.href = data.downloadUrl
        } catch (err) {
            console.warn(err)
        }
    }

    return (
        <>
            <Heading name="Last Imported Files" />

            {eodFile && (
                <>
                    <h6>EOD</h6>

                    <Container>
                        <span>{eodDate}</span>
                        <span>{eodFile.id} </span>
                        <DownloadButton downloadItem={downloadItem} id={eodFile.id} type="eod" />
                    </Container>
                </>
            )}

            {bomFile && (
                <>
                    <h6>BOM</h6>

                    <Container>
                        <span>{bomDate}</span>
                        <span>{bomFile.id} </span>
                        <DownloadButton downloadItem={downloadItem} id={bomFile.id} type="bom" />
                    </Container>
                </>
            )}

            {deltaFile && (
                <>
                    <h6>Delta</h6>

                    <Container>
                        <span>{deltaDate}</span>
                        <span>{deltaFile.id} </span>
                        <DownloadButton
                            downloadItem={downloadItem}
                            id={deltaFile.id}
                            type="delta"
                        />
                    </Container>
                </>
            )}
        </>
    )
}
