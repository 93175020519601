import React, { useState, useEffect, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { useClient, GET_MANY, DELETE } from '@peracto/client'
import { toast } from 'react-toastify'
import { bool } from 'prop-types'
import { Field } from 'formik'

import {
    FormActions,
    TimeField,
    Select,
    Group,
    Input,
    Modal,
    Form,
    Tags,
    RichText,
} from '@peracto/peracto-ui'

import LocationImages from './LocationImages'

const LocationsForm = ({
    data,
    modeAdd = false,
    modeEdit = false,
    onDuplicate = () => {},
    ...props
}) => {
    const { client } = useClient()
    const [countries, setCountries] = useState()
    const [redirect, setRedirect] = useState()
    const [modalOpen, toggleModal] = useState(false)
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const fetchCountries = useCallback(async () => {
        try {
            const { data: countryData, response: countryResponse } = await client(
                GET_MANY,
                'countries',
                {
                    id: 'countries',
                }
            )

            if (countryResponse.status === 404) {
                setRedirect('/locations')
            }

            setCountries(countryData)
        } catch (e) {
            console.error(e)
            setRedirect('/locations')
        }
    }, [client])

    const onDelete = async () => {
        try {
            await client(DELETE, 'locations', {
                id: data.id,
            })

            toast.success('Location deleted successfully!')
            setRedirect('/locations')
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const getCountryOptions = () =>
        countries ? countries.map((country) => ({ label: country.label, value: country.iso3 })) : []

    const getCountryDefaultValue = (field, options) => {
        const found = options.find((option) => {
            return option.label === field.value
        })
        return found || { label: '', value: '' }
    }

    useEffect(() => {
        fetchCountries()
    }, [fetchCountries])

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            <FormActions
                children={
                    modeEdit ? (
                        <>
                            <button
                                className="btn btn--outline-primary p-0"
                                onClick={() => setShowDuplicateDialog(true)}
                            >
                                <FontAwesomeIcon icon={faCopy} className="mr-2" />
                                Duplicate
                            </button>
                            <button
                                className="btn btn--outline-primary text-danger"
                                onClick={() => toggleModal(!modalOpen)}
                            >
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                Delete Location
                            </button>
                        </>
                    ) : null
                }
            />
            <Form autoComplete="false" values={data} {...props}>
                <Group name="Location" id="location">
                    <Input label="Location name" required name="name" />

                    <Tags
                        name="locationTypes"
                        label="Location Type"
                        options={[
                            { label: 'Branch', value: 'branch' },
                            { label: 'Stockist', value: 'stockist' },
                            { label: 'Warehouse', value: 'warehouse' },
                            { label: 'Office', value: 'office' },
                            { label: 'Click And Collect', value: 'click_and_collect' },
                            { label: 'RFC', value: 'rfc' },
                        ]}
                    />
                    <Select
                        placeholder="Select Status"
                        name="locationStatus"
                        options={[
                            { label: 'Open', value: 'open' },
                            { label: 'Closed', value: 'closed' },
                            { label: 'Opening Soon', value: 'opening_soon' },
                            { label: 'Closing Down', value: 'closing_down' },
                        ]}
                        label="Status"
                        required
                    />
                    <Select
                        placeholder="Select Location Page"
                        name="locationPageStatus"
                        options={[
                            { label: 'Active', value: 'active' },
                            { label: 'Inactive', value: 'inactive' },
                        ]}
                        label="Location Page"
                        defaultValue={{ label: 'Active', value: 'active' }}
                    />
                    <Field name="alias">
                        {({ form, field }) => {
                            return (
                                <>
                                    {(form.values.locationPageStatus === 'active' ||
                                        !form.values.locationPageStatus) && (
                                        <Input name={field.name} required label="Alias" />
                                    )}
                                </>
                            )
                        }}
                    </Field>
                    <Input label="Slug" name="slug" required />

                    <Input label="K8 Branch ID" name="k8BranchId" required />
                </Group>
                <Group name="Address" id="address">
                    <Input name="address1" label="Address 1" required />
                    <Input name="address2" label="Address 2" />
                    <Input name="address3" label="Address 3" />
                    <Input name="city" label="Town / City" required />
                    <Input name="county" label="County / State" required />
                    <Input name="postcode" label="Postal Code" required />
                    <Field name="country">
                        {({ field }) => {
                            const options = getCountryOptions()
                            return countries && options ? (
                                <Select
                                    defaultValue={getCountryDefaultValue(field, options)}
                                    name={field.name}
                                    label="Country"
                                    options={options}
                                    placeholder="Select a country"
                                    required
                                />
                            ) : (
                                <p className="help-text">Loading...</p>
                            )
                        }}
                    </Field>

                    <Input name="latitude" label="Latitude" required />
                    <Input name="longitude" label="Longitude" required />
                </Group>
                <Group name="Contact information" id="contact-info">
                    <Input name="website" label="Website" />
                    <Input name="telephone" label="Telephone Number" />
                    <Input name="email" label="Email" />
                </Group>
                <Group name="Opening hours" id="opening-hours">
                    <div className="form-row">
                        {days.map((day) => (
                            <React.Fragment key={day}>
                                <div className="col-12 col-md-6">
                                    <TimeField
                                        name={`openingHours.${day.toLowerCase()}.opening`}
                                        label={`${day} Opening`}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <TimeField
                                        name={`openingHours.${day.toLowerCase()}.closing`}
                                        label={`${day} Closing`}
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    <RichText label="Special Opening Hours" name="specialOpeningHours" />
                </Group>
                <Group name="Content and images" id="content-and-images">
                    <RichText name="generalInformation" label="General information" />
                    <LocationImages />
                </Group>
            </Form>

            {modeEdit && (
                <>
                    <Modal
                        isVisible={modalOpen}
                        title="Delete User"
                        close={() => toggleModal(!modalOpen)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => toggleModal(!modalOpen),
                            },
                            {
                                type: 'btn-danger',
                                text: 'Delete Location',
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="d-block mb-4"
                        />
                        Are you sure you would like to permanently delete {data.name}? Deleted
                        locations cannot be recovered.
                    </Modal>

                    <Modal
                        isVisible={showDuplicateDialog}
                        title="Duplicate Location"
                        close={() => setShowDuplicateDialog(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => setShowDuplicateDialog(false),
                            },
                            {
                                type: 'btn-success',
                                text: 'Duplicate Location',
                                action: () => onDuplicate(data),
                            },
                        ]}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} size="4x" className="d-block mb-4" />
                        Are you sure you would like to duplicate this location?
                    </Modal>
                </>
            )}
        </>
    )
}

LocationsForm.propTypes = {
    modeAdd: bool,
    modeEdit: bool,
}

export default LocationsForm
