import React, { useState } from 'react'
import { Field, FieldArray } from 'formik'
import { Form, Group, Input, Select, Modal } from '@peracto/peracto-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { toast } from 'react-toastify'

import { DELETE, useClient } from '@peracto/client'

import * as S from './styled'

const EditAndCloseBtns = ({ visibleFields, idx, setVisibleFields }) => {
    return (
        <button
            type="button"
            className="btn btn-link p-0 d-flex align-items-center"
            onClick={() =>
                setVisibleFields({
                    ...visibleFields,
                    [`address-${idx}`]: !visibleFields[`address-${idx}`],
                })
            }
        >
            {visibleFields[`address-${idx}`] ? (
                <>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Close
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                    Edit
                </>
            )}
        </button>
    )
}

const AddressForm = ({
    values,
    visibleFields,
    setVisibleFields,
    countries,
    onSaveAddress,
    onSaveResidentialAddress,
    setFormData,
    ...props
}) => {
    const [showAddressDialog, setShowAddressDialog] = useState(false)
    const [addressDialogContent, setAddressDialogContent] = useState({})
    const { client } = useClient()

    const StyledAddress = ({ address, title, withSpacing }) => {
        return (
            <S.Address withSpacing={withSpacing}>
                {title && <h6>{title}:</h6>}
                {
                    <p>
                        {address?.firstName} {address?.lastName}
                    </p>
                }
                {address.addressLine1 && <p>{address.addressLine1}</p>}
                {address.addressLine2 && <p>{address.addressLine2}</p>}
                {address.addressLine3 && <p>{address.addressLine3}</p>}
                {address.addressLine4 && <p>{address.addressLine4}</p>}
                {address.city && <p>{address.city}</p>}
                {address.county && <p>{address.county}</p>}
                {address.postcode && <p>{address.postcode}</p>}
                {address.country && <p>{getCountry(address.country)}</p>}
            </S.Address>
        )
    }

    const addressRow = {
        title: ' ',
        firstName: ' ',
        lastName: ' ',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
        type: ['delivery'],
    }

    const showBillingAddresses =
        values.user.tradingAddress ||
        values.user.registeredAddress ||
        values.user.residentialAddress

    const onDeleteAddress = async (addressId) => {
        try {
            await client(DELETE, 'addresses', {
                id: addressId,
            })

            toast.success('Address deleted successfully!')

            setFormData({
                ...values,
                addresses: values.addresses.filter((address) => address['@id'] !== addressId),
            })

            setVisibleFields({})
            setShowAddressDialog(false)
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const getCountry = (value) => {
        const country = countries.find((country) => value === country.iso3)
        return country ? country.label : value
    }

    const getCountryOptions = (type) => {
        return countries
            .filter((country) => {
                if (
                    (!country.validForBilling && type === 'delivery') ||
                    (!country.validForDelivery && type === 'billing')
                ) {
                    return false
                }

                return true
            })
            .map((country) => ({ label: country.label, value: country.iso3 }))
    }

    return (
        <>
            <Form showActionPanel={false} autoComplete="off" values={values} {...props}>
                <Group key="addresses" id="addresses" name="Delivery Addresses">
                    <Field name="addresses">
                        {({ form, field }) => (
                            <FieldArray name={field.name}>
                                {({ push }) => (
                                    <div>
                                        {field.value.length > 0 ? (
                                            field.value.map((address, idx) => {
                                                // Only show delivery addresses which aren't residential/trading/registered
                                                if (
                                                    address.type.includes('residential') ||
                                                    address.type.includes('trading') ||
                                                    address.type.includes('registered') ||
                                                    !address.type.includes('delivery')
                                                )
                                                    return null
                                                return (
                                                    <div
                                                        className="d-flex justify-content-between align-items-start mb-3"
                                                        key={`address-${address.originId}`}
                                                    >
                                                        {!visibleFields[`address-${idx}`] && (
                                                            <StyledAddress address={address} />
                                                        )}
                                                        {visibleFields[`address-${idx}`] && (
                                                            <div className="w-100">
                                                                <Input
                                                                    name={`addresses.${idx}.type`}
                                                                    type="hidden"
                                                                    value={'delivery'}
                                                                    readonly
                                                                />

                                                                <Input
                                                                    name={`addresses.${idx}.firstName`}
                                                                    label="First Name"
                                                                    required
                                                                />

                                                                <Input
                                                                    name={`addresses.${idx}.lastName`}
                                                                    label="Last Name"
                                                                    required
                                                                />

                                                                <Input
                                                                    name={`addresses.${idx}.addressLine1`}
                                                                    label="Address Line 1"
                                                                />
                                                                <Input
                                                                    name={`addresses.${idx}.addressLine2`}
                                                                    label="Address Line 2"
                                                                />
                                                                <Input
                                                                    name={`addresses.${idx}.addressLine3`}
                                                                    label="Address Line 3"
                                                                />
                                                                <Input
                                                                    name={`addresses.${idx}.addressLine4`}
                                                                    label="Address Line 4"
                                                                />
                                                                <Input
                                                                    name={`addresses.${idx}.city`}
                                                                    label="City"
                                                                />
                                                                <Input
                                                                    name={`addresses.${idx}.county`}
                                                                    label="County"
                                                                />
                                                                <Input
                                                                    name={`addresses.${idx}.postcode`}
                                                                    label="Postcode"
                                                                />
                                                                <Select
                                                                    name={`addresses.${idx}.country`}
                                                                    label="Country"
                                                                    options={getCountryOptions(
                                                                        field.value[idx].type
                                                                    )}
                                                                    placeholder="Select a country"
                                                                />

                                                                <div className="d-flex">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success d-flex align-items-center"
                                                                        onClick={() => {
                                                                            onSaveAddress({
                                                                                ...address,
                                                                                type: ['delivery'],
                                                                            })
                                                                            setVisibleFields({
                                                                                ...visibleFields,
                                                                                [`address-${idx}`]: false,
                                                                            })
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faSave}
                                                                            className="mr-2"
                                                                        />{' '}
                                                                        Save Address
                                                                    </button>

                                                                    {field.value[idx].id && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-danger d-flex align-items-center ml-2"
                                                                            onClick={() => {
                                                                                setAddressDialogContent(
                                                                                    address
                                                                                )
                                                                                setShowAddressDialog(
                                                                                    true
                                                                                )
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}
                                                                                className="mr-2"
                                                                            />{' '}
                                                                            Delete Address
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}

                                                        <EditAndCloseBtns
                                                            visibleFields={visibleFields}
                                                            idx={idx}
                                                            setVisibleFields={setVisibleFields}
                                                        />
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <p className="text-center mt-3 mb-0">
                                                No addresses are assigned to this user.
                                            </p>
                                        )}

                                        <div className="d-flex justify-content-end mb-2">
                                            <button
                                                className="btn btn-sm btn-outline-primary my-2"
                                                type="button"
                                                onClick={() => {
                                                    push(addressRow)
                                                    setVisibleFields({
                                                        ...visibleFields,
                                                        [`address-${field.value.length}`]: true,
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                                                Add New Address
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        )}
                    </Field>
                </Group>

                {showBillingAddresses && (
                    <Group id="billing-addresses" name="Billing Addresses">
                        {values.user.tradingAddress && (
                            <StyledAddress
                                address={values.user.tradingAddress}
                                title="Trading"
                                withSpacing
                            />
                        )}
                        {values.user.registeredAddress && (
                            <StyledAddress
                                address={values.user.registeredAddress}
                                title="Registered"
                                withSpacing
                            />
                        )}
                        {values.user.residentialAddress && (
                            <div className="d-flex justify-content-between align-items-start">
                                <StyledAddress
                                    address={values.user.residentialAddress}
                                    title="Residential"
                                    withSpacing
                                />
                                <button
                                    type="button"
                                    className="btn btn-link p-0 d-flex align-items-center"
                                    onClick={() => {
                                        setVisibleFields({
                                            ...visibleFields,
                                            user: !visibleFields.user,
                                        })
                                    }}
                                >
                                    {visibleFields.user ? (
                                        <>
                                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            Close
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                            Edit
                                        </>
                                    )}
                                </button>
                            </div>
                        )}

                        {!!visibleFields.user && (
                            <Field name="user">
                                {({ form, field }) => (
                                    <>
                                        <div className="w-100">
                                            <Input
                                                name={`user.residentialAddress.addressLine1`}
                                                label="Address Line 1"
                                            />
                                            <Input
                                                name={`user.residentialAddress.addressLine2`}
                                                label="Address Line 2"
                                            />
                                            <Input
                                                name={`user.residentialAddress.addressLine3`}
                                                label="Address Line 3"
                                            />
                                            <Input
                                                name={`user.residentialAddress.addressLine4`}
                                                label="Address Line 4"
                                            />
                                            <Input
                                                name={`user.residentialAddress.city`}
                                                label="City"
                                            />
                                            <Input
                                                name={`user.residentialAddress.county`}
                                                label="County"
                                            />
                                            <Input
                                                name={`user.residentialAddress.postcode`}
                                                label="Postcode"
                                            />
                                            <Select
                                                name={`user.residentialAddress.country`}
                                                label="Country"
                                                options={getCountryOptions(field.value.type)}
                                                placeholder="Select a country"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-success d-flex align-items-center"
                                            onClick={() => {
                                                onSaveResidentialAddress(
                                                    field.value.residentialAddress,
                                                    values
                                                )
                                                setVisibleFields({
                                                    ...visibleFields,
                                                    user: false,
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSave} className="mr-2" /> Save
                                            Address
                                        </button>
                                    </>
                                )}
                            </Field>
                        )}
                    </Group>
                )}
            </Form>

            <Modal
                isVisible={showAddressDialog}
                title="Delete Address"
                close={() => setShowAddressDialog(false)}
                buttons={[
                    {
                        type: 'btn-outline-secondary',
                        text: 'Close',
                        action: () => setShowAddressDialog(false),
                    },
                    {
                        type: 'btn-danger',
                        text: 'Delete Address',
                        action: () => onDeleteAddress(addressDialogContent['@id']),
                    },
                ]}
            >
                <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className="d-block mb-4" />
                Are you sure you would like to permanently delete the address?
            </Modal>
        </>
    )
}

export default AddressForm
