import React, { useState, useEffect, useCallback } from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

import { Input, Select, Tags, Checkbox, Modal } from '@peracto/peracto-ui'
import { GET_LIST, useClient } from '@peracto/client'

import DiscountsRow from './DiscountsRow'

const Discounts = ({ name }) => {
    const { client } = useClient()
    const [shippingServiceOptions, setShippingServiceOptions] = useState([])
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [warningModalDiscount, setWarningModalDiscount] = useState()

    const loadShippingServices = useCallback(async () => {
        const { data } = await client(GET_LIST, 'shipping-services', {
            id: 'shipping-services',
            pagination: { perPage: 10000 },
            filter: {
                status: 'active',
            },
        })

        const options = data.map((service) => ({
            label: service.name,
            value: service.originId,
        }))

        setShippingServiceOptions(options)
    }, [client])

    useEffect(() => {
        loadShippingServices()
    }, [loadShippingServices])

    const defaultDiscount = {
        type: 'percentage',
        value: 0,
        trigger_products: [],
        target_products: [],
    }

    // To be populated with further types as they're added
    const DISCOUNT_TYPES = [
        {
            label: 'Percentage',
            value: 'percentage',
        },
        {
            label: 'Fixed',
            value: 'fixed',
        },
        {
            label: 'Shipping Percentage',
            value: 'shipping.percentage',
        },
        {
            label: 'Free Product',
            value: 'free_product',
        },
        {
            label: 'Free Product, Same Quantity',
            value: 'free_product_same_quantity',
        },
    ]

    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => {
                            return (
                                <div className="form-group">
                                    {field.value && field.value.length > 0 ? (
                                        field.value.map((option, index) => {
                                            const type = form?.values[field.name][index]['type']

                                            return (
                                                <div
                                                    key={`discount_${index}`}
                                                    className="border-bottom mb-3 pb-3"
                                                >
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <div className="row">
                                                                <div
                                                                    className="col-12 col-md-6"
                                                                    data-testid="promotion-discount-type"
                                                                >
                                                                    <Select
                                                                        label="Type"
                                                                        name={`${field.name}.${index}.type`}
                                                                        options={DISCOUNT_TYPES}
                                                                    />
                                                                </div>

                                                                {type === 'free_product' && (
                                                                    <>
                                                                        <div className="col-12 col-md-6">
                                                                            <Input
                                                                                label="Quantity Discount Cap"
                                                                                name={`${field.name}.${index}.quantity_discount_cap`}
                                                                                testId="promotion-discount-cap"
                                                                                type="number"
                                                                                min="0"
                                                                                tooltip="Use to limit the maximum number of free units. By default, free Product units will always be equal to paid Product units. E.g. Buy 1 get 1 free, or Buy 100 get 100 free."
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            <div className="d-flex align-items-center justify-content-start h-100">
                                                                                <Checkbox
                                                                                    name={`${field.name}.${index}.automatically_add_product`}
                                                                                    label="Automatically add the free product to basket"
                                                                                    defaultValue={
                                                                                        true
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        const discountTargetProducts =
                                                                                            form
                                                                                                .values[
                                                                                                field
                                                                                                    .name
                                                                                            ][index]
                                                                                                ?.target_products

                                                                                        const invalidOperators =
                                                                                            discountTargetProducts?.filter(
                                                                                                (
                                                                                                    product
                                                                                                ) => {
                                                                                                    return (
                                                                                                        ![
                                                                                                            'sku',
                                                                                                            'quantity',
                                                                                                        ].includes(
                                                                                                            product
                                                                                                                .condition
                                                                                                                .code
                                                                                                        ) ||
                                                                                                        (product
                                                                                                            .condition
                                                                                                            .code ===
                                                                                                            'sku' &&
                                                                                                            product.operation !==
                                                                                                                '=') ||
                                                                                                        (product
                                                                                                            .condition
                                                                                                            .code ===
                                                                                                            'quantity' &&
                                                                                                            product.operation !==
                                                                                                                '<=')
                                                                                                    )
                                                                                                }
                                                                                            )

                                                                                        if (
                                                                                            e.target
                                                                                                .checked ===
                                                                                                true &&
                                                                                            invalidOperators.length >
                                                                                                0
                                                                                        ) {
                                                                                            setWarningModalDiscount(
                                                                                                `${field.name}.${index}`
                                                                                            )
                                                                                            setShowWarningModal(
                                                                                                true
                                                                                            )
                                                                                        } else {
                                                                                            form.setFieldValue(
                                                                                                `${field.name}.${index}.automatically_add_product`,
                                                                                                e
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {type ===
                                                                    'free_product_same_quantity' && (
                                                                    <div className="col-12 col-md-6">
                                                                        <Input
                                                                            label="Quantity Discount Cap"
                                                                            name={`${field.name}.${index}.quantity_discount_cap`}
                                                                            testId="promotion-discount-cap"
                                                                            type="number"
                                                                            min="0"
                                                                            tooltip="Use to limit the maximum number of free units. By default, free Product units will always be equal to paid Product units. E.g. Buy 1 get 1 free, or Buy 100 get 100 free."
                                                                        />
                                                                    </div>
                                                                )}

                                                                {![
                                                                    'free_product',
                                                                    'free_product_same_quantity',
                                                                ].includes(type) && (
                                                                    <div className="col-12 col-md-6">
                                                                        <Input
                                                                            label="Discount Value"
                                                                            name={`${field.name}.${index}.value`}
                                                                            type="number"
                                                                            min="0"
                                                                            max={
                                                                                type === 'fixed'
                                                                                    ? ''
                                                                                    : '100'
                                                                            }
                                                                            prefix={
                                                                                type === 'fixed' &&
                                                                                '\u00A3'
                                                                            }
                                                                            step={
                                                                                type === 'fixed'
                                                                                    ? 0.01
                                                                                    : 1
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {type === 'shipping.percentage' && (
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <Tags
                                                                            name={`${field.name}.${index}.shipping_services`}
                                                                            label="Shipping Services"
                                                                            options={
                                                                                shippingServiceOptions
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-2">
                                                            {index !== 0 && (
                                                                <div className="d-flex align-items-center justify-content-end h-100">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link text-danger px-0 text-nowrap"
                                                                        onClick={() =>
                                                                            remove(index)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                        />{' '}
                                                                        Remove Discount
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <DiscountsRow
                                                        {...{
                                                            index,
                                                            form,
                                                            field,
                                                            remove,
                                                            name: `${field.name}.${index}.trigger_products`,
                                                            type: 'trigger_products',
                                                            heading: 'Trigger Products',
                                                            help: 'Use conditions to create rules that have to be met for the discount to be eligible to apply.',
                                                        }}
                                                    />

                                                    {form.values[field.name][index].type !==
                                                        'free_product_same_quantity' && (
                                                        <DiscountsRow
                                                            {...{
                                                                index,
                                                                form,
                                                                field,
                                                                remove,
                                                                name: `${field.name}.${index}.target_products`,
                                                                type: 'target_products',
                                                                heading: 'Target Products',
                                                                help: 'Use conditions to limit the products that the discount will apply to if all other conditions are met.',
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <p className="text-center my-4 mb-0">
                                            No discounts have been assigned to this promotion
                                        </p>
                                    )}

                                    {!field.value && (
                                        <p className="py-3 my-3 text-center">
                                            No Conditions applied
                                        </p>
                                    )}

                                    <div className="d-flex align-items-center justify-content-end mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-link px-0 mt-2"
                                            onClick={() => {
                                                push(defaultDiscount)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                            Add new Discount
                                        </button>
                                    </div>
                                </div>
                            )
                        }}
                    </FieldArray>

                    <Modal
                        isVisible={showWarningModal}
                        title="Unset Target Products"
                        close={() => setShowWarningModal(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => {
                                    setShowWarningModal(false)
                                },
                            },
                            {
                                type: 'btn-danger',
                                text: 'Continue and Remove Rules',
                                action: () => {
                                    form.setFieldValue(
                                        `${warningModalDiscount}.target_products`,
                                        []
                                    )
                                    form.setFieldValue(
                                        `${warningModalDiscount}.automatically_add_product`,
                                        true
                                    )
                                    setShowWarningModal(false)
                                },
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="d-block mb-4"
                        />
                        Changing this discount setting will remove existing Target Product Rules.
                    </Modal>
                </>
            )}
        </Field>
    )
}

export default Discounts
