import React from "react";
import { Heading } from "@peracto/peracto-ui";
import RequestForm from "./RequestForm";

const IntegrationsForm = () => {
  return (
    <section className="form-container">
      <Heading name="Integrations" />
      <RequestForm name="Kerridge Data" type="kerridge" />
      <RequestForm name="GSR Data" type="gsr" />
    </section>
  );
};

export default IntegrationsForm;
