import React from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

import MinimumRequirementsRow from './MinimumRequirementsRow'

const MinimumRequirements = ({ name }) => {
    const defaultCondition = {
        value: '1',
        condition: 'basket.units',
        operation: '=',
    }

    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => {
                            return (
                                <div className="form-group">
                                    {field.value && field.value.length > 0 ? (
                                        <>
                                            <p className="mt-3">
                                                The following must be true to attempt to apply the
                                                discount.{' '}
                                            </p>

                                            {field.value.map((option, index) => (
                                                <div key={`requirement_${index}`}>
                                                    <MinimumRequirementsRow
                                                        {...{
                                                            index,
                                                            form,
                                                            field,
                                                            remove,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <p className="py-3 my-3 text-center">
                                            No requirements have been applied to this promotion
                                        </p>
                                    )}

                                    <div className="d-flex align-items-center justify-content-end mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-link px-0 mt-2"
                                            onClick={() => {
                                                push(defaultCondition)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                            Add New Requirement
                                        </button>
                                    </div>
                                </div>
                            )
                        }}
                    </FieldArray>
                </>
            )}
        </Field>
    )
}

export default MinimumRequirements
