import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { GET_LIST, useClient } from '@peracto/client'
import { useConfig } from '@peracto/peracto-config'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import {
    StyledCheckbox,
    SelectFilter,
    TextFilter,
    DateRangeFilter,
    HydraTable,
    Heading,
    TimeAgo,
} from '@peracto/peracto-ui'
import { userRoles } from '../utils'

const UserList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        roles: true,
        createdAt: true,
        lastLoggedInAt: true,
        telephone: false,
    })
    const [customerGroups, setCustomerGroups] = useState([])

    const { client } = useClient()
    const config = useConfig()
    const formatDate = config.get('user_format_date', config.get('format_date'))

    const fetchCustomerGroups = useCallback(async () => {
        const { data } = await client(GET_LIST, 'customer-groups', {
            id: 'customer-groups',
        })

        const values = data.map((val) => ({
            label: val.name,
            value: val.id,
        }))

        setCustomerGroups(values)

        return values
    }, [client])

    useEffect(() => {
        fetchCustomerGroups()
    }, [fetchCustomerGroups])

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <StyledCheckbox {...row.getToggleRowSelectedProps()} />,
            selectable: false,
        },
        {
            id: 'avatar',
            Cell: ({ row }) => {
                return (
                    <img
                        src={`https://eu.ui-avatars.com/api/?name=${row.values.firstName}+${row.values.lastName}&background=ededed&&color=666&rounded=true`}
                        alt=""
                    />
                )
            },
            selectable: false,
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            sortType: 'basic',
            Filter: (props) => <TextFilter label="First Name" {...props} />,
            Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,
            selectable: false,
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            sortType: 'basic',
            Filter: (props) => <TextFilter label="Last Name" {...props} />,
            Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,
            selectable: false,
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: 'basic',
            Filter: (props) => <TextFilter label="Email" type="email" {...props} />,
            selectable: false,
        },
        {
            Header: 'Roles',
            accessor: 'roles',
            sortType: 'basic',
            Filter: (props) => (
                <SelectFilter label="Roles" options={userRoles} isMulti={true} {...props} />
            ),
            selectable: true,
            Cell: (row) =>
                row.cell.value.map((val) => {
                    const found = userRoles.find((role) => role.value === val)
                    return found ? (
                        <span
                            key={`role-${row.row.original.id}-${val}`}
                            className={classNames('badge', 'badge-pill', 'mr-1', {
                                'badge-info': val === 'ROLE_ADMIN',
                                'badge-success': val === 'ROLE_USER',
                                'badge-light': val === 'ROLE_COMMERCIAL',
                                'badge-secondary': ['ROLE_FINANCE', 'ROLE_PARTS_ARENA'].includes(
                                    val
                                ),
                                'badge-danger': val === 'ROLE_MARKETING',
                                'badge-warning': val === 'ROLE_OPERATIONS',
                                'badge-dark': val === 'ROLE_TRADE_SUPPORT',
                                'badge-primary': val === 'ROLE_SUPER_ADMIN',
                            })}
                        >
                            {found.label}
                        </span>
                    ) : (
                        ''
                    )
                }),
            show: selectedColumns['roles'],
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            sortType: 'basic',
            Cell: ({ cell: { value } }) =>
                value ? <TimeAgo title={formatDate(new Date(value))} date={value} /> : '-',
            Filter: (props) => <DateRangeFilter label="Created" {...props} />,
            selectable: true,
            show: selectedColumns['createdAt'],
        },
        {
            Header: 'Last login',
            accessor: 'lastLoggedInAt',
            sortType: 'basic',
            Cell: ({ cell: { value } }) =>
                value ? <TimeAgo title={formatDate(new Date(value))} date={value} /> : '-',
            Filter: (props) => <DateRangeFilter label="Last login" {...props} />,
            selectable: true,
            show: selectedColumns['lastLoggedInAt'],
        },
        {
            Header: 'Telephone',
            accessor: 'telephone',
            sortType: 'basic',
            Cell: ({ cell: { value } }) => (value ? value : '-'),
            Filter: (props) => <TextFilter label="Telephone" type="tel" {...props} />,
            selectable: true,
            show: selectedColumns['telephone'],
        },
        {
            Filter: (props) => <TextFilter label="Credit Application Status" {...props} />,
            Cell: ({ cell: { value } }) => (value ? value : '-'),
            show: selectedColumns['creditApplicationStatus'],
            Header: 'Credit Application Status',
            accessor: 'creditApplicationStatus',
            sortType: 'basic',
            selectable: true,
        },
        {
            Header: 'Cash Application Status',
            accessor: 'cashApplicationStatus',
            sortType: 'basic',
            Cell: ({ cell: { value } }) => (value ? value : '-'),
            Filter: (props) => <TextFilter label="Cash Application Status" {...props} />,
            selectable: true,
            show: selectedColumns['cashApplicationStatus'],
        },
        {
            Header: 'Peracto Customer Group',
            accessor: 'customerGroup.name',
            sortType: 'basic',
            Cell: ({ cell: { value } }) => (value ? value : '-'),
            Filter: (props) => (
                <SelectFilter label="Peracto Customer Group" options={customerGroups} {...props} />
            ),
            selectable: true,
            show: selectedColumns['customerGroup'],
        },
        {
            Filter: (props) => <TextFilter label="GSR Status" {...props} />,
            Cell: ({ cell: { value } }) => (value ? value : '-'),
            show: selectedColumns['gsrStatus'],
            accessor: 'gsrStatus',
            Header: 'GSR Status',
            sortType: 'basic',
            selectable: true,
        },
        {
            Filter: (props) => <TextFilter label="Postcode" {...props} />,
            Cell: ({ cell: { value } }) => (value ? value : '-'),
            show: selectedColumns['tradingAddress.postcode'],
            accessor: 'tradingAddress.postcode',
            Header: 'Postcode',
            sortType: 'basic',
            selectable: true,
        },
    ]

    const onFetchData = (options) => client(GET_LIST, 'users', options)

    return (
        <>
            <Heading name="Users">
                <div className="d-flex align-items-center ml-2">
                    <Link className="btn btn-primary" to="/users/add">
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add User
                    </Link>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    firstName: '',
                                    lastName: '',
                                    email: '',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList
