import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'

import PromotionCodeInput from './PromotionCodeInput'

const PromotionCodes = ({ name }) => (
    <Field name={name}>
        {({ field }) => (
            <FieldArray name={field.name}>
                {({ push, remove }) => (
                    <>
                        {field?.value?.length > 0 && (
                            <table className="table table-sm">
                                <colgroup>
                                    <col width="50%" />
                                    <col width="20%" />
                                    <col width="20%" />
                                    <col width="10%" />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Maximum Uses</th>
                                        <th>Max. Uses Per Email</th>
                                        <th className="text-right">Remove</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {field.value.map((item, index) => (
                                        <tr key={`promotion-code-${index}`}>
                                            <td>{item.code}</td>
                                            <td>{item.maximumUses}</td>
                                            <td>{item.maximumUsesPerEmail}</td>
                                            <td className="text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        <div className="form-group">
                            <PromotionCodeInput push={push} />
                        </div>
                    </>
                )}
            </FieldArray>
        )}
    </Field>
)

PromotionCodes.propTypes = {
    name: PropTypes.string.isRequired,
}

export default PromotionCodes
