import React from 'react'
import { Select, Input } from '@peracto/peracto-ui'
import { GET_LIST, useClient } from '@peracto/client'
import AsyncSelect from 'react-select/async'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import debounce from 'debounce-promise'

const CONDITION_TYPES = [
    {
        label: 'SKU',
        value: {
            label: 'SKU',
            code: 'sku',
            dataType: 'string',
        },
    },
    {
        label: 'Quantity',
        value: {
            label: 'Quantity',
            code: 'quantity',
            dataType: 'int',
        },
    },
    {
        label: 'MPN',
        value: {
            label: 'MPN',
            code: 'mpn',
            dataType: 'string',
        },
    },
    {
        label: 'GTIN',
        value: {
            label: 'GTIN',
            code: 'gtin',
            dataType: 'string',
        },
    },
]

const FREE_PRODUCT_CONDITION_TYPES = [
    {
        label: 'SKU',
        value: {
            label: 'SKU',
            code: 'sku',
            dataType: 'string',
        },
    },
    {
        label: 'Quantity',
        value: {
            label: 'Quantity',
            code: 'quantity',
            dataType: 'int',
        },
    },
]

export const stringOperatorValues = [
    { label: 'Equals', value: '=' },
    { label: 'Not equal to', value: '!=' },
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'does_not_contain' },
]

export const operatorValues = [
    { label: 'Equals', value: '=' },
    { label: 'Greater than', value: '>' },
    { label: 'Less than', value: '<' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Less than or equal', value: '<=' },
]

const freeProductStringOperatorValues = [{ label: 'Equals', value: '=' }]
const freeProductOperatorValues = [
    { label: 'Less than or equal', value: '<=' },
    { label: 'Is a multiple of', value: '*' },
]

const STRING_OPERATOR_TYPES = ['text', 'string']

const DiscountsRow = ({ index, form, field, remove, type, heading, help }) => {
    const { client } = useClient()

    const discount = form.values.discounts[index]

    const isAutoAddProduct =
        (discount.hasOwnProperty('automatically_add_product') &&
            discount.automatically_add_product === true) ||
        discount.type === 'free_product_same_quantity'

    const fetchAttributes = async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'attributes', {
            id: 'attributes',
            filter: { label: inputValue, allowedInPromotions: true },
        })

        const filteredTypes = isAutoAddProduct
            ? FREE_PRODUCT_CONDITION_TYPES.filter(
                  (val) =>
                      !(
                          val.value.code === 'quantity' &&
                          discount.type === 'free_product_same_quantity'
                      )
              )
            : CONDITION_TYPES

        const defaultValues = filteredTypes.filter(
            (condition) =>
                condition.value.code.includes(inputValue) ||
                condition.value.label.includes(inputValue)
        )

        const values = data.map((attr) => ({
            label: attr.label,
            value: {
                label: attr.label,
                code: attr.code,
                dataType: attr.dataType,
            },
        }))

        if (
            (isAutoAddProduct && type === 'target_products') ||
            discount.type === 'free_product_same_quantity'
        ) {
            return defaultValues
        } else {
            return [...defaultValues, ...values]
        }
    }

    const debouncedFetchAttributes = debounce(fetchAttributes, 200)

    return (
        <div key={`discount_${index}_${type}`} className="mb-3">
            <h6>{heading}</h6>
            <p>{help}</p>
            <Field name={`${field.name}.${index}.${type}`}>
                {({ form, field: innerField }) => (
                    <FieldArray name={innerField.name}>
                        {({ push, remove }) => (
                            <>
                                {innerField.value && innerField.value.length > 0 ? (
                                    innerField.value.map((option, valIdx) => (
                                        <div
                                            className="row mb-1"
                                            key={`${innerField.name}_${valIdx}`}
                                        >
                                            <div className="col-12">
                                                <div className="form-row">
                                                    <div className="col-12 col-md-4">
                                                        <Field
                                                            name={`${innerField.name}.${valIdx}.condition`}
                                                        >
                                                            {({ form, field }) => (
                                                                <>
                                                                    <label className="mb-1">
                                                                        Attribute
                                                                    </label>
                                                                    <AsyncSelect
                                                                        className="w-100"
                                                                        loadOptions={(input) =>
                                                                            debouncedFetchAttributes(
                                                                                input
                                                                            )
                                                                        }
                                                                        isSearchable={true}
                                                                        onChange={(option) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                option.value
                                                                            )

                                                                            if (
                                                                                form.values
                                                                                    ?.discounts[
                                                                                    index
                                                                                ]
                                                                                    ?.automatically_add_product ===
                                                                                    true &&
                                                                                option.value
                                                                                    .code ===
                                                                                    'quantity'
                                                                            ) {
                                                                                form.setFieldValue(
                                                                                    `${innerField.name}.${valIdx}.operation`,
                                                                                    '<='
                                                                                )
                                                                            }
                                                                        }}
                                                                        value={{
                                                                            label:
                                                                                field?.value
                                                                                    ?.label ||
                                                                                field?.value,
                                                                            value: field.value,
                                                                        }}
                                                                        placeholder="Search for Attributes to add..."
                                                                        noOptionsMessage={({
                                                                            inputValue,
                                                                        }) => {
                                                                            if (
                                                                                inputValue.length >
                                                                                0
                                                                            ) {
                                                                                return `No results found for '${inputValue}'.`
                                                                            } else {
                                                                                return 'Enter text to begin searching.'
                                                                            }
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <Field
                                                            name={`${innerField.name}.${valIdx}.operation`}
                                                        >
                                                            {({ field }) => {
                                                                // Don't look.
                                                                // tl;dr - the operator differ between string and non-string
                                                                // options, and then if it's an auto add product then the
                                                                // options are reduced to only feature the ones that are supported
                                                                const limitOperatorValues =
                                                                    (type === 'target_products' &&
                                                                        isAutoAddProduct) ||
                                                                    discount.type ===
                                                                        'free_product_same_quantity'

                                                                const options = innerField.value[
                                                                    valIdx
                                                                ].condition.dataType
                                                                    ? STRING_OPERATOR_TYPES.includes(
                                                                          innerField.value[valIdx]
                                                                              .condition.dataType
                                                                      )
                                                                        ? limitOperatorValues
                                                                            ? freeProductStringOperatorValues
                                                                            : stringOperatorValues
                                                                        : limitOperatorValues
                                                                        ? freeProductOperatorValues
                                                                        : operatorValues
                                                                    : limitOperatorValues
                                                                    ? freeProductStringOperatorValues
                                                                    : stringOperatorValues

                                                                return (
                                                                    <Select
                                                                        label="Operator"
                                                                        name={field.name}
                                                                        options={options}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </div>

                                                    <div className="col-12 col-md-3">
                                                        <Input
                                                            label="Value"
                                                            name={`${innerField.name}.${valIdx}.value`}
                                                            min="0"
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-1">
                                                        <div className="d-flex align-items-center justify-content-end h-100">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger"
                                                                onClick={() => {
                                                                    remove(valIdx)
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center my-4 mb-0">
                                        No {heading} have been assigned to this discount
                                    </p>
                                )}

                                <div className="d-flex align-items-center justify-content-end mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            push({
                                                condition: CONDITION_TYPES[0]['value'],
                                                operation: '=',
                                                value: '',
                                            })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} /> Add new Condition
                                    </button>
                                </div>
                            </>
                        )}
                    </FieldArray>
                )}
            </Field>
        </div>
    )
}

export default DiscountsRow
