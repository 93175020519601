import React from "react";
import { Route, Switch } from "react-router-dom";
import IntegrationsForm from "./IntegrationsForm/index";

export default () => {
    return {
        routes: [
            {
                path: "/integrations",
                render: ({ match }) => (
                    <Switch>
                        <Route path={`${match.url}`} exact component={IntegrationsForm} />
                    </Switch>
                ),
            },
        ],
    };
};
